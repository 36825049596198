<template>
  <v-container>

    <v-col cols="12">
      <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="200"
      />
    </v-col>

    <v-row class="justify-center mt-10">
      <v-col cols="6">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="validate"
        >
          <v-text-field
              v-model="username"
              :rules="fieldRules.usernameRules.concat(fieldRules.max200Chars)"
              autofocus
              clearable
              counter="200"
              label="Korisničko ime"
              required
          ></v-text-field>

          <v-text-field
              v-model="password"
              :rules="fieldRules.passwordRules.concat(fieldRules.max200Chars)"
              clearable
              counter="200"
              label="Lozinka"
              required
              type="password"
          ></v-text-field>

          <v-checkbox
              v-model="checkbox"
              label="Zapamti me"
          ></v-checkbox>

          <v-btn
              :disabled="!valid"
              class="info mr-4 white--text"
              type="submit"
              @click="validate"
          >
            Prijavi se
          </v-btn>
          <v-alert :value="showAlert"
                   class="mt-5"
                   transition="fade-transition"
                   type="error"
          >
            Nalog ili lozinka <strong>nisu</strong> ispravni ili ne postoje
          </v-alert>
        </v-form>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>

import state from '../store/index'
import router, {rolesMap} from '../router/index'

import {fieldRules} from "@/mixins/field-rules";
import store from "@/store";

export default {
  name: "LoginView",
  data: () => ({
    valid: true,
    username: '',
    password: '',
    fieldRules: [],
    checkbox: true,
    user: null,
    showAlert: false
  }),
  created() {
    this.fieldRules = fieldRules
  },
  methods: {
    validate() {
      if (this.$refs.form.validate())
        this.submitForm()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    submitForm() {
      this.$store.dispatch('auth/login', {username: this.username, password: this.password}).then((res) => {
        if (res.success) {
          if (res.user.role_id !== 3) {
            res.user.role_id = parseInt(res.user.role_id)
            state.dispatch("auth/setUser", res.user)
            state.dispatch("auth/setRole", res.user)
            state.dispatch("auth/setAuthToken", res.access_token)
            state.dispatch("auth/setAuthenticated", true)
            const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']))
            router.push({name: userRole.redirect}).catch(err => {
              // Ignore the vuex err regarding  navigating to the page they are already on.
              if (
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
              ) {
                // But print any other errors to the console
                // eslint-disable-next-line no-console
                console.log(err);
              }
            });
          }
        } else {
          this.showErrorBlock()
        }
      }).catch(() => {
        this.showErrorBlock()
      })

    },
    showErrorBlock() {
      this.showAlert = true
      window.setInterval(() => {
        this.showAlert = false;
      }, 2000)
    },
  },
  components: {}
}

</script>
